.body,
body,
.priceTable {
    max-width: 100vw;

}

.priceTable {
    overflow: auto;
    display: block;
}

.body {

    background-color: #f3f5f6;
}

.dashboard {
    background-color: #f3f5f6;
    min-height: 100vh;

    header {
        text-align: end;

        .left {
            float: left;
            text-align: left;
        }

        .rigth {
            float: right;
        }
    }

    .price {
        font-size: 20px;
        color: #12d2b7;

        small {
            font-size: small;
            color: #999;
        }
    }

    section.container {
        display: grid;
        grid-template-columns: 200px 1fr;
        gap: 30px;

        >div {
            padding: 20px;
            border: 1px solid #dadada30;
            border-radius: 10px;
            background-color: #fff;
            box-shadow: 1px 1px 9px 6px #7a8ab30f;
        }

        .table {
            grid-row: 1/5;
            grid-column: 2;
            margin: 0;
            margin-bottom: 30px;

            header {
                h3 {
                    color: #03153a;
                }
            }

            section {
                padding: 18px 0;
                display: grid;
                width: 100%;
                grid-auto-flow: column;
                overflow: auto;

                >div {
                    padding: 10px 15px;
                    margin: 0 10px 0 0;
                    border: 1px solid #e7e9ea;
                    border-radius: 5px;
                    min-width: 109px;
                    cursor: pointer;

                    &.active {
                        border: 1px solid #1ba9ef;
                        border-radius: 5px;
                        box-shadow: 0 0 13px 1px #e1ecff;
                    }

                    h3 {
                        font-size: 30px;
                    }

                    h5 {
                        opacity: 0.7
                    }
                }
            }
        }

        .Rate {
            b {
                display: flex;
                justify-content: space-between;
                align-items: center;
                border-bottom: 1px solid #dadada20;
                margin-bottom: 3px;
                padding-bottom: 2px;
            }

            .roomName {
                color: #8d96a6;
                font-size: 14px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            .roomRate {}
        }

        .occupancy {
            grid-row: 1;
            grid-row: 2;
            text-align: center;
            max-height: 190px;

            h4 {
                color: #778295;
                margin-bottom: 10px;
            }

            p {
                font-size: 23px;
                font-weight: bold;
                color: #929292;
                padding: 9px 0;
                line-height: 1;

                span {
                    color: #13d2b7;
                    font-size: 38px;
                }
            }

            ul {
                li {
                    display: block;
                    text-align: start;
                    padding: 0 20px;
                    position: relative;
                    display: flex;
                    justify-content: space-between;
                    margin-top: 10px;

                    &::before {
                        content: "";
                        position: absolute;
                        width: 13px;
                        height: 13px;
                        top: 2px;
                        left: 0;
                        border-radius: 50%;
                    }
                }

                li.booked {
                    &::before {
                        background-color: #13d2b7;
                    }
                }

                li.available {
                    &::before {
                        background-color: #929292;
                    }
                }
            }
        }

        .today {
            display: grid;
            font-weight: 600;
            max-height: 190px;

            .day {
                opacity: 0.8;
            }

            .num {
                font-size: calc(30px + 2vw);
                color: #778295;
            }

            .mon {
                font-size: calc(10px + 0.8vw);
                color: #8d96a6;
            }
        }
    }

    .table {
        td {
            vertical-align: middle;
        }

    }
}

@media screen and (max-width:560px) {
    .dashboard section.container {
        display: grid;
        grid-template-columns: 1fr;
        gap: 30px;

        .table {
            overflow: auto;
            display: block;
        }

        .table,
        .today,
        .occupancy {
            grid-row: unset;
            grid-column: unset;
            text-align: center;
        }
    }

    .dashboard section.container .table section {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 10px 0;
    }
}