.about {
    border: 1px solid #dadada;
    position: relative;
    padding: 14px 20px;
    margin: 14px 0px;
    border-radius: 4px;

    >h5 {
        font-weight: 400;
        display: inline-block;
        position: absolute;
        top: -9px;
        left: 10px;
        background: #fff;
        padding: 0 5px;
    }

    ∑ span {
        font-size: 13px;
        color: #666;

        b {
            color: #000000;
        }
    }

    footer {
        .red {
            color: red;
        }

        border-top: 1px solid #dadada;
        text-align: center;
        padding: 9px;
        margin-top: 13px;
        line-height: 1;
        padding-bottom: 0;

        span {
            font-size: 14px;
        }
    }
}

.flex3 {
    display: flex;
    gap: 10px;
    justify-content: space-between;

    >p {
        width: 100%;
    }
}

@media screen and (max-width:700px) {
    .about .flex {
        grid-template-columns: 1fr;
    }

}

.items3grid {
    display: grid;
    grid-template-columns: 1fr auto auto;
    gap: 10px;
    justify-content: flex-end;
    align-items: baseline;

    .btn {
        color: #fff;
        background-color: #17a2b8;
        border-color: #17a2b8;

        &.main {
            background-color: #343a40;
            border-color: #343a40;
        }

        &.secondary {
            color: #fff;
            background-color: #6c757d;
            border-color: #6c757d;
        }
    }
}