@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}


.load {
    position: fixed;
    right: 0;
    bottom: 0;
    z-index: 99999999;
    display: grid;
    justify-content: center;
    align-items: center;
    padding: 10px;

    .tar {
        border: 7px solid #f3f3f365;
        border-radius: 50%;
        border-top: 7px solid #34db50;
        width: 40px;
        height: 40px;
        -webkit-animation: spin 1s linear infinite;
        animation: spin 1s linear infinite;
    }
}