.btn.full {
    display: block;
    width: 100%;
}

.card.empty {
    opacity: 0.4;
}

span.success {
    color: #38ad65;
}


.cloaps {
    .card-in { display: none; }

    label {
        text-align: center;
        width: 100%;
        background: #dadada30;
        padding: 6px;
        cursor: pointer;
        border: 1px solid #dadada40;
        border-radius: 5px;
        margin-top: 10px;
        display: flex;
        justify-content: space-between;
        h5 {
            display: inline-block;
            padding: 0 4px;
        }
    }

    input[type='checkbox'] {
        display: none;
    }

    input:checked~.card-in {
        display: block;
    }
}
.empty h5 {
    text-align: center;
    width: 100%;
    display: block;
    background: #dadada30;
    padding: 6px;
    cursor: not-allowed;
    border: 1px solid #dadada40;
    border-radius: 5px;
    margin-top: 10px;
    font-weight: normal;
    font-size: 15px;
}


.btn.btn-light.main.btn-sm.Payment {
    background-color: #00870036;
}

.btn.btn-light.main.btn-sm.Deposit {
    background-color: #007e874d;
}

.btn.btn-light.main.btn-sm.Charge {
    background-color: #8e8e8e4d;
}


input[readonly] {
    opacity: 0.8;
    background: #dadada !important;
    cursor: no-drop;
}

.updateShow,
.showPrice {
    .btn.main {
        background: #dafff8;
        padding: 0;
        border-radius: 0;
    }
}

.flex.genPrice {
    display: grid;
    grid-template-columns: 1fr 80px;
    gap: 0;
    align-items: end;
    align-content: end;
    margin-bottom: 6px;

    p.btn {
        background-color: #38ad65;
        padding: 0;
        margin: 0;
        height: 38px;
        border-radius: 0 5px 5px 0;
        display: grid;
        font-size: 14px;
        justify-content: center;
        align-items: center;
        font-weight: bold;
        color: #fff;
    }
}