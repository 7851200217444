.list--update {
    display: grid;
    grid-template-columns: 100px 1fr auto;
    justify-content: center;
    align-items: center;
    padding: 10px;
    gap: 10px;
    background-color: #fff;
    margin-bottom: 6px;
    border: 1px solid #dadada57;
    border-radius: 4px;
    font-size: 14px;
    div {
        text-align: center;
        display: flex;
        justify-content: center;
        gap: 6px;
    }

    .bed {
        background: #dadada;
        line-height: 1;
        padding: 2px 5px;
        margin: 0 3px;
        border-radius: 4px;
        color: #858585;
        font-weight: bold;

        &.n {
            background: #9ed1ff;
            color: #1159a1;
        }
    }

    &.list--update.cancelled .status .a {
        color: rgb(183, 6, 0) !important;
    }

    &.list--update.modified .status .a {
        color: rgb(0 125 183) !important;
    }

    &.list--update.new .status .a {
        color: rgb(18, 183, 0) !important;
    }

    .platform {
        padding: 3px 6px;
        background: #dadada;
        line-height: 1;
        border-radius: 4px;
        color: #555555;
        font-size: 13px;
        margin: 0 6px;
    }
}

.e_statue {
    padding: 3px 9px;
    line-height: 1;
    border: 1px solid;
    border-radius: 4px;
    font-size: 13px;
    color: #fff;
    background: #d7d7d7;
    &.s {
        opacity: 0.6;
    }
}