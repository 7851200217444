@media print {

    .h1,
    h1 {
        font-size: 1.6rem;
    }

    .h2,
    h2 {
        font-size: 1.5rem;
    }

    .h3,
    h3 {
        font-size: 1.4rem;
    }

    .h4,
    h4 {
        font-size: 0.8rem;
    }

    .h5,
    h5 {
        font-size: 1.2rem;
    }

    .h6,
    h6,
    .p,
    p,
    body {
        font-size: 0.7rem;
    }

    li::before {
        content: " - ";
    }

    .modal.show {
        overflow: inherit;

    }

    .modal.show .modal-dialog {
        width: 100%;
        overflow: inherit;
        margin: 0 auto;
        min-width: 100%;
        max-width: 100%;
    }

    .modal {
        position: relative !important;
    }

    .nop,
    div.nop,
    button.nop {
        display: none !important;
        opacity: 0 !important;
        visibility: hidden !important;
    }

    div.print {
        display: block !important;
        opacity: 1 !important;
        visibility: unset !important;

        * {
            opacity: 1 !important;
            visibility: unset !important;
            display: block !important;

        }
    }

    .modal-content,
    .modal-body,
    .card {
        border: unset !important;
        padding: 0 0 5px 0 !important;
        margin: 0 0 5px 0 !important;
    }

    .pdis,
    .pdis.print,
    div.pdis.print,
    div.pdis {
        opacity: 1 !important;
        color: #000 !important;
        visibility: unset !important;
        display: block !important;

    }
}