.container.gpt {
    font-family: Arial, Helvetica, sans-serif;
    >.chat {
        display: grid;

        >span {
            padding: 10px;
            margin: 10px;
            border-radius: 5px;

        }

        .gpt {
            background-color: rgb(58, 58, 58);
            text-align: start;
        }

        .user {
            background-color: rgb(72, 72, 72);
            text-align: end;
        }
    }
    .snd {
        display: grid;
        grid-template-columns: 1fr auto;
    }
}