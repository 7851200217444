.priceTable {
    background: #fff;
    margin: 4px;
    border: 1px solid #dadada50;
    border-radius: 5px;
    box-shadow: 0 0 23px 0px #00000020;
    padding: 15px 0;

    .datPrice {
        min-width: 50px;
    }

    .thead {
        width: 190px;
        justify-content: center;
        align-items: center;
        display: flex;
        position: sticky;
        left: 0px;
        background: #fff;
        z-index: 1;
    }

    td.thead {
        padding: 10px;
    }


    tbody>tr >td.datPrice, 
    tbody>tr >td, 
    tbody>tr >*{ 
        border-top: 1px solid #999 !important;
    }

    tbody,
    thead {
        display: block;

        tr {

            display: grid;
            grid-auto-flow: column;
            grid-template-columns: 1fr repeat(19, 1fr);
        }

        .datPrice {
            // width: 70px;

            >span {
                font-size: 12px;
                opacity: 0.5;
                font-weight: 400;
            }
        }
    }

    .empty {
        width: 100%;
        height: 100%;
        background-color: #ffccc7;
        border: 1px solid #ffccc7;
        display: grid;
        justify-content: center;
        align-items: center;
        color: #939393;
    }

    td.datPrice {
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        border: 1px solid #fafafa;
        border-top: unset !important;
        border-bottom: unset !important;

        &:hover {
            border: 1px solid #ffd591;
            background-color: #ffd591;
        }
    }
}