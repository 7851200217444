.ReactModal__Overlay {
    z-index: 9;
}

.grid-p p  {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
    margin-bottom: 5px;
}
.center {
    text-align: center;
}

.grid-p  p strong {
    text-align: right;
}