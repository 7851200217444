.flex {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;

    .end {
        justify-self: flex-end;
    }

    input[type=date] {
        width: 100%;
    }
}

img {
    max-width: 100%;
    max-height: 100%;
}

.form-control {
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    border: 1px solid #ced4da;
    border-radius: 0.375rem;
    transition: border-color .15s ease-in-out,
        box-shadow .15s ease-in-out;

    &:disabled {
        background-color: #eeeeee;
        cursor: not-allowed;
    }
}

label.box {
    padding: 1px 6px;
    color: #6666ff;
    font-weight: bold;
    // background: #fafafa;
    // border: 1px solid #dadada70;
    display: inline-flex;
    font-size: 13px;
    gap: 6px;
    justify-content: center;
    align-items: center;
    line-height: 1;

    input {
        padding: 0 !important;
        margin: 0 !important;
    }
}

select,
textarea,
input {
    margin-bottom: 7px !important;
}

input[type=date] {
    background-color: #fff;
    border: 1px solid #dadada;
    padding: 7px 20px;
    border-radius: 5px;
    font-size: 16px;
}

label.tl {
    position: relative;
    display: block;

    >span {
        position: absolute;
        left: 3px;
        top: -6px;
        background-color: #fff;
        font-size: 14px;
        color: #555;
        padding: 0 5px;
        opacity: 05;
        border-radius: 4px;
    }
}

.container {
    width: 100%;
    max-width: 960px;
    padding: 1rem 1.5rem;
    margin-right: auto;
    margin-left: auto;
}

.ml-4 { margin-left: 10px; }
.ml-3 { margin-left: 8px; }
.ml-2 { margin-left: 5px; }
.ml-1 { margin-left: 3px; }

.pl-4 { padding-left: 10px; }
.pl-3 { padding-left: 8px; }
.pl-2 { padding-left: 5px; }
.pl-1 { padding-left: 3px; }

.p-4 { padding: 10px; }
.p-3 { padding: 8px; }
.p-2 { padding: 5px; }
.p-1 { padding: 3px; }

.m-4 { margin: 10px; }
.m-3 { margin: 8px; }
.m-2 { margin: 5px; }
.m-1 { margin: 3px; }

@media (min-width: 576px) {
    .container {
        max-width: 540px;
    }
}

@media (min-width: 768px) {
    .container {
        max-width: 720px;
    }
}

@media (min-width: 992px) {
    .container {
        max-width: 960px;
    }
}

@media (min-width: 1200px) {
    .container {
        max-width: 1140px;
    }
}

.container-fluid {
    width: 100%;
    padding: 1rem 1.5rem;
    margin-right: auto;
    margin-left: auto;
}



.bs-example>.btn,
.bs-example>.btn-group {
    margin-top: 5px;
    margin-bottom: 5px;
}

.btn {
    display: inline-block;
    padding: 6px 12px;
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.42857143;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-image: none;
    border: 1px solid transparent;
    opacity: 0.9;
    border-radius: 4px;
}

button,
input,
select,
textarea {
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}


.btn-primary {
    color: #fff;
    background-color: #0d6efd;
}

.btn-secondary {
    color: #fff;
    background-color: #6c757d;
}

.btn-success {
    color: #fff;
    background-color: #198754
}

.btn-danger {
    color: #fff;
    background-color: #dc3545
}

.btn-warning {
    color: #000;
    background-color: #ffc107
}

.btn-info {
    color: #000;
    background-color: #0dcaf0
}

.btn:hover {
    opacity: 1;
}

select {
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    -moz-padding-start: calc(0.75rem - 3px);
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-repeat: no-repeat;
    background-position: right 0.75rem center;
    background-size: 16px 12px;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.table {
    width: 100%;
    margin-bottom: 1rem;
    color: #212529;
    background-color: transparent;
    border-collapse: collapse;
    border-spacing: 0;

    &.table-white {
        background-color: #fff;
    }
}

.sm-table {
    .outgoing {
        background-color: #fff0f0;
        color: #f27777;
    }

    .income {
        background-color: #f1faf1;
        color: #007c07;
    }

    >h4 {
        text-align: center;
    }

    table {
        th {
            font-size: 13px;
            padding: 4px 3px;
            font-weight: normal;
        }

        .description {
            max-width: 80px;
            white-space: pre;
            overflow: auto;
        }
    }
}

.table th,
.table td {
    padding: 0.5rem;
    vertical-align: top;
    border: 1px solid #dee2e6 !important;
    border-top: 0 !important; // Remove top border on table cells
}

.table thead th {
    vertical-align: bottom;
    border-top: 2px solid #dee2e6; // Add top border for table headers
}

button:disabled {
    cursor: not-allowed !important;
    background-color: #6c757d !important;
}

.drop_save {
    display: grid;
    grid-template-columns: 1fr auto;
    justify-content: center;
    align-items: center;
    margin-bottom: 6px;

    select,
    p {
        margin: 0 !important;
    }

    select {
        border-radius: 4px 0 0 4px;
        max-height: 34px;
        font-size: 14px;
    }

    >p {
        border-radius: 0 4px 4px 0;
    }
}

.sm.info,
.sm.warning {
    display: block;
    font-size: 11px;
}

.sm.warning {
    color: #df2424;
}

.Signature canvas {
    background-color: #fff;
    border: 1px solid #dadada;
    width: 100%;
    border-radius: 3px;
    margin: 8px 0 0 0;
    // border-color: #;
}

.nav {
    display: flex;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
    border-bottom: 1px solid #dee2e6;
}

.nav-item {
    margin-bottom: -1px;
}

.nav-link {
    display: block;
    padding: 0.5rem 1rem;
    text-decoration: none;
    background-color: transparent;
    border: 1px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
    color: #007bff;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
}

.nav-link:hover,
.nav-link:focus {
    border-color: #e9ecef #e9ecef #dee2e6;
    color: #0056b3;
    background-color: #e9ecef;
}

.nav-link.disabled {
    color: #6c757d;
    background-color: transparent;
    pointer-events: none;
    cursor: default;
}

.nav-link.active {
    color: #495057;
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff;
}