.table {
    td {
        vertical-align: middle;
    }

    .flex-select {
        display: grid;
        min-width: 170px;
        grid-template-columns: 1fr auto;
        margin-bottom: 0px !important;
        gap: 0 !important;


        select {
            height: 28px;
            padding: 0px 6px !important;
            margin: 0 !important;
            border-radius: 4px 0 0 4px;
            font-size: 14px;
            color: #555;
        }

        button {
            opacity: 0.8;
            font-size: 14px;
            padding: 0px 6px !important;
            border-radius: 0 4px 4px 0;
            height: 100%;

            &:hover {
                opacity: 1;

            }

        }
    }
}