
.alert-container {
    opacity: 1;
    position: fixed;
    z-index: 100;
    bottom: 10px;
    right: calc(50vw - 100px);
    background-color: whitesmoke;
    border-radius: 7px;
    box-shadow: 0 1px 4px #1c4963;
    overflow: hidden;
    cursor: pointer;
    transition: 0.5s;
    z-index: 9999;
    >.alert-inner {
        padding: 10px 20px;
        font-weight: 600;
        color: #444;
        display: flex;
        gap: 10px;
        align-items: center;

        .circle {
            background-color: #444;
        }

        &.error {
            color: #de3b12;

            .circle {
                background-color: #de3b12;
            }
        }

        &.success {
            color: #38ad65;

            .circle {
                background-color: #38ad65;
            }
        }
    }



    .circle {
        width: 25px;
        height: 25px;
        display: grid;
        justify-content: center;
        align-items: center;
        font-weight: 900;
        border-radius: 100%;
        color: whitesmoke;
    }

    &:hover {
        opacity: 1;
    }
}