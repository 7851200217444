@import "./build/reset.scss";
@import "./build/colors.scss";
@import "./build/login.scss";
// @import "./build/responsive.scss";
@import "./build/new_responsive.scss";
@import "./build/printer.scss";
@import "./build/priceTable.scss";
@import "./build/table-new.scss";
@import "./build/header-new.scss";
@import "./build/table.scss";
@import "./build/loader.scss";
@import "./build/popup.scss";
@import "./build/side-popup.scss";
@import "./build/btns.scss";
@import "./build/notification.scss";
@import "./build/logs.scss";
@import "./build/date-picker-lib.scss";
@import "./build/gpt.scss";
@import "./build/extend.scss";
@import "./build/dashboard.scss";
@import "./build/accordion.scss";
@import "./build/invoice.scss";
@import "./build/chat.scss";
@import "./build/new-bootStrap.scss";
@import "./build/fixTable.scss";
@import "./build/popup-sections.scss";
@import "./build/new-guest.scss";

#root>main,
body {
  font-family: "Roboto", sans-serif;
  min-height: 100vh;
}

#root>main {
  display: grid;
  grid-template-rows: auto 1fr;
}

div[id^="react-select"] {
  color: #000 !important;
}

label.plus {
  position: relative;

  // span.check {
  //     position: absolute;
  //     bottom: 10px;
  //     color: #000;
  //     left: 10px;
  // }
}

/* Works for Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Works for Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

label {
  position: relative;

  >span.check {
    position: absolute;
    right: 14px;
    top: 32px;
    color: #00000090;
    padding: 0 10px;
    border-radius: 4px;

    &.new {
      background-color: #00ef0050;
      color: #008700;
    }

    &.Old {
      background-color: #ef000050;
      color: #870000;
    }
  }
}

.qr-placeholder {
  margin: 0 auto;
  text-align: center;

  svg {
    width: 80%;
    height: 100%;
    max-height: 60vh;
    margin: 0 auto;
  }
}

.textArea {
  width: 100%;
  padding: 5px 10px;
  border: 1px solid #dadada;
  border-radius: 4px;
}

.grid-dates {
  display: grid;
  gap: 10px;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  max-height: 200px;
  overflow: auto;
  margin: 10px 0;

  >.btn {
    border: 1px solid #dadada;
  }

  input {
    width: 0;
    height: 0;
  }

  input:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    z-index: -1;
    left: 0;
  }

  input:checked {
    &:before {
      background: #67a6ee;
    }
  }

  label {
    overflow: hidden;
    cursor: pointer;
    position: relative;
  }
}

.email {
  .verified {
    color: #00b963;
    font-weight: 500;
    text-transform: capitalize;
  }

  .pending {
    color: $red;
    font-weight: 500;
    text-transform: capitalize;
  }
}

.rateInput {
  text-align: center;

  i>span {
    opacity: 0.5;
  }

  button {
    padding: 5px;
    margin-top: 10px;
    text-decoration: underline;
  }

  input {
    background-color: transparent;
    width: 80px;
    text-align: center;
    opacity: 1;
    border: unset !important;
  }
}