.accordion-main {
    display: grid;

    >input {
        opacity: 0;
        display: none;
        width: 0;
        height: 0;

        &:checked~div {
            display: block;
        }

        &:checked~label {
            color: #0c63e4 !important;
            background-color: #e7f1ff !important;
        }
    }

    >label {
        cursor: pointer;
        text-align: center;
        text-transform: capitalize;
        border: 1px solid #e6e6e6;
        box-shadow: inset 0 -1px 0 rgba(0, 0, 0, .125);
        position: relative;
        display: flex;
        align-items: center;
        width: 100%;
        padding: 10px;
        font-size: 14px;
        color: #212529;
        text-align: left;
        background-color: #fff;
        border-radius: 0;
        overflow-anchor: none;
        transition: color .15s;
        background-color: #fff !important;
        color: #000 !important;
    }

    >div {
        display: none;
        border-left: 1px solid #dadada;
        border-right: 1px solid #dadada;
        border-bottom: 1px solid #dadada;
        padding: 1px;
        border-radius: 0px 0 5px 5px;
    }
}