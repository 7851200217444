.invoice-pdf {
    width: 445px;
    display: block;
    padding: 5%;
    font-family: Arial, Helvetica, sans-serif;
    div { font-size: 8px; color: #000; }
    table { font-size: 7px; color: #000; }
    a {
        color: #5D6975;
        text-decoration: underline;
    }

    h1 {
        border-top: 1px solid #5D6975;
        border-bottom: 1px solid #5D6975;
        font-size: 0.9em;
        color: #5D6975;
        font-weight: normal;
        text-align: center;
        margin: 0 0 5px 0;
    }

    #project {
        float: left;
    }

    #project span {
        color: #5D6975;
        text-align: right;
        width: 33px;
        margin-right: 7px;
        display: inline-block;
        font-size: 0.8em;
    }

    #company {
        float: right;
        text-align: right;
    }

    #project div,
    #company div {
        white-space: nowrap;
    }

    table {
        width: 100%;
        border-collapse: collapse;
        border-spacing: 0;
        margin-bottom: 5px;
    }

    table tr:nth-child(2n-1) td {
        background: #F5F5F5;
    }

    table td {
        text-align: center;
    }

    table th {
        padding: 5px 5px;
        color: #5D6975;
        border-bottom: 1px solid #C1CED9;
        white-space: nowrap;
        font-weight: normal;
    }

    table .service,
    table .desc {
        text-align: left;
    }

    table td {
        padding: 5px;
        text-align: left;
    }

    table td.service,
    table td.desc {
        vertical-align: top;
    }

    table td.unit,
    table td.qty,
    table td.total {
        font-size: 1.2em;
    }

    table td.grand {
        border-top: 1px solid #5D6975;
        ;
    }

    #notices .notice {
        color: #5D6975;
        font-size: 1.2em;
    }

    footer {
        color: #5D6975;
        width: 100%;
        height: 30px;
        position: absolute;
        bottom: 0;
        border-top: 1px solid #C1CED9;
        padding: 8px 0;
        text-align: center;
    }
}